import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetActivityCancellationAndClosureReasons } from './__generated__/GetActivityCancellationAndClosureReasons';
import { ActivityReasonEditForm } from './ActivityReasonEditForm';
import { ActivityReasonType } from './admin-console-operations-model';
import { GET_ACTIVITY_CANCELLATION_CLOSURE_REASONS } from './admin-console-operations.gql';

const AdminConsoleOperationsPanel = () => {
  const { data: allReasonsResponse, loading: allReasonsLoading, refetch: refetchReasons } = useCampfireQuery<
    GetActivityCancellationAndClosureReasons,
    undefined
  >(GET_ACTIVITY_CANCELLATION_CLOSURE_REASONS);

  const initialCancellationReasonValues = useMemo(() => {
    if (!allReasonsResponse?.vm.activityCancellationReasons) return { reasons: [] };
    return {
      reasons: allReasonsResponse.vm.activityCancellationReasons.map((reason) => {
        return {
          activityCancellationReasonId: reason.activityCancellationReasonId,
          label: reason.label,
          listId: uuidv4(),
        };
      }),
    };
  }, [allReasonsResponse]);

  const initialClosureReasonValues = useMemo(() => {
    if (!allReasonsResponse?.vm.activityClosureReasons) return { reasons: [] };
    return {
      reasons: allReasonsResponse.vm.activityClosureReasons.map((reason) => {
        return {
          activityClosureReasonId: reason.activityClosureReasonId,
          label: reason.label,
          listId: uuidv4(),
        };
      }),
    };
  }, [allReasonsResponse]);

  return (
    <Box position='relative' width={1} display='flex' justifyContent='center'>
      <LinearProgressOverlay isLoading={allReasonsLoading} />
      <Box px={8} pt={4} width={1} maxWidth={720}>
        <ActivityReasonEditForm
          initialValues={initialCancellationReasonValues}
          type={ActivityReasonType.cancellation}
          refetchReasons={refetchReasons}
        />
        <ActivityReasonEditForm
          initialValues={initialClosureReasonValues}
          type={ActivityReasonType.closure}
          refetchReasons={refetchReasons}
        />
        <Typography variant='h6' style={{ fontWeight: 600, paddingBottom: '.5em' }}>
          Reporting
        </Typography>
      </Box>
    </Box>
  );
};

export { AdminConsoleOperationsPanel };
